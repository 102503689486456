import React, {FC} from "react";
import {Tag} from "@customTypes/tags";
import stylesSessions from "../SessionForm/SessionForm.module.scss";
import NavTitle from "../../../../../components/typography/NavTitle/NavTitle";
import Container from "../../../../../components/Container/Container";
import ScheduleForm from "./ScheduleForm";
import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {CircularProgress, Fade} from "@mui/material";
import Box from "@mui/material/Box";
import {getScheduleFormInitialData} from "@services/schedule/editScheduleService";

export const getScheduleQueryOptions = (scheduleId: string) => ({
    queryKey: ['schedule', scheduleId],
    queryFn: () => getScheduleFormInitialData(scheduleId),
});

const ScheduleScreen: FC = () => {
    const {scheduleId} = useParams<{ scheduleId: string | undefined }>();

    const {data, isLoading} = useQuery({
        ...getScheduleQueryOptions(scheduleId as string),
        enabled: !!scheduleId

    })

    return (
        <Container as={Tag.Section} classes={stylesSessions.wrapper}>
            <NavTitle>{!!scheduleId ? 'Edycja harmonogramu' : 'Tworzenie nowego harmonogramu'}</NavTitle>
            {!!scheduleId && !data && isLoading
                ? (
                    <Box sx={{display: 'flex', paddingTop: 6, justifyContent: 'center'}}>
                        <CircularProgress size={45}/>
                    </Box>
                ) : (
                    <Fade in>
                        <div>
                            <ScheduleForm data={data}/>
                        </div>
                    </Fade>
                )
            }
        </Container>
    )
}

export default ScheduleScreen