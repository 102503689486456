import {SessionCalendarEventResponse} from "@customTypes/sessionCalendarEventResponse";
import {Slot} from "@customTypes/slot";

/**
 * Parses session calendar event response to Slot object.
 * Converts session date and hour into start and end Date objects.
 * @param dto SessionCalendarEventResponse
 * @returns Slot
 */
export const dataParser = (dto: SessionCalendarEventResponse): Slot => {
    const startDateTime = new Date(`${dto.sessionDate}T${dto.hour}`);
    const endDateTime = new Date(startDateTime.getTime() + 60 * 60 * 1000); // Adds 1 hour to start time
    return {
        id: dto.calendarEventId,
        title: `${startDateTime.getHours()}:${startDateTime.getMinutes() < 10 ? '0' : ''}${startDateTime.getMinutes()}`,
        start: startDateTime,
        end: endDateTime,
        available: dto.available,
        mentorTimeZone: dto.mentorTimeZone,
    };
};