import {CalendarSlot} from "@customTypes/calendarSlot";
import moment from "moment-timezone";

/**
 * Adjusts calendar slots to the mentee's time zone.
 * Calculates time difference between mentor and mentee and updates the hour field.
 * @param slots List of calendar slots
 * @param menteeTimeZone Time zone of the mentee
 * @returns Updated calendar slots
 */
export const adjustCalendarSlots = (slots: CalendarSlot[], menteeTimeZone: string): CalendarSlot[] => {
    return slots.map(slot => {
        const mentorDateTime = moment.tz(`${slot.sessionDate}T${slot.hour}`, slot.mentorTimeZone);
        const mentorOffset = mentorDateTime.utcOffset(); // Mentor UTC offset in minutes
        const menteeOffset = moment.tz(mentorDateTime, menteeTimeZone).utcOffset(); // Mentee UTC offset in minutes
        const timeDifferenceInHours = (mentorOffset - menteeOffset) / 60; // Time difference in hours

        const adjustedDateTime = mentorDateTime.add(timeDifferenceInHours, 'hours'); // Adjust mentor time by the difference
        return {
            ...slot,
            hour: adjustedDateTime.format('HH:mm'), // Update hour with mentee time
        };
    });
};