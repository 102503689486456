import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import clx from "classnames";

import Button, {ButtonVariant} from "src/components/Button/Button";
import Modal from "src/components/Modal/Modal";
import {Text} from "src/components/typography";
import {TextareaField} from "../TextareaField/TextareaField";

import styles from "./ContactMentorPopup.module.scss";
import {Loader} from "../../_grouped/loader";
import {resolver} from "./resolver";
import {ContactInputField} from "../ContactInputField/ContactInputField";
import {sendMentorMessage} from "@services/mentorMessageService/mentorMessage.service";
import {useParams} from "react-router-dom";

type ContactMentorPopupProps = {
    isOpen: boolean;
    handleClose: () => void;
    setResponseMsg: Dispatch<SetStateAction<string>>;
};
export type ContactMentorFormTypes = {
    email: string;
    message: string;
    authorName: string;
};

export const ContactMentorPopup = ({
                                       isOpen,
                                       handleClose,
                                       setResponseMsg,
                                   }: ContactMentorPopupProps) => {
    const [isPending, setIsPending] = useState<boolean>(false);
    const {
        register,
        formState: {errors},
        handleSubmit,
        reset: resetForm,
    } = useForm<ContactMentorFormTypes>({resolver,});
    const {username} = useParams<{ username: string }>();


    const onSubmit = handleSubmit(async (data) => {
        if (!username) {
            setResponseMsg("Brakuje identyfikatora mentora w URL.");
            return;
        }
        setIsPending(true);
        try {
            const response = await sendMentorMessage({
                username,
                email: data.email,
                message: data.message,
            });
            if (response?.success && setResponseMsg) {
                setResponseMsg("Twoja wiadomość została wysłana do mentora!");
            }

            if (!response?.success) {
                setResponseMsg(response.errorMessage || "Wystąpił błąd.");
            }
            setIsPending(false);
        } catch (err) {
            console.log(err);
        }

        handleClose();
    });

    useEffect(() => {
        if (isOpen) {
            resetForm();
        }
    }, [isOpen, resetForm]);


    const {
        name: emailInputName,
        onChange: onEmailInputChange,
        ref: emailInputRef,
    } = register("email");
    const {
        name: messageTextareaName,
        onChange: onMessageTextareaChange,
        ref: textareaRef,
    } = register("message");

    if (!isOpen) return null;

    return (
        <Modal
            className={styles.modal}
            classNameContent={styles.modalContent}
            title="Wyślij wiadomość do mentora"
            closeHandler={handleClose}
        >
            <Text classes={styles.info}>Napisz do mentora, a odpowie najszybciej jak to możliwe!</Text>
            <form onSubmit={onSubmit}>
                <ContactInputField
                    name={emailInputName}
                    id="email"
                    label="Twój e-mail"
                    onChange={onEmailInputChange}
                    inputRef={emailInputRef}
                    error={errors?.email?.message}
                />
                <TextareaField
                    name={messageTextareaName}
                    id="message"
                    label="Treść wiadomości"
                    onChange={onMessageTextareaChange}
                    textareaRef={textareaRef}
                    error={errors?.message?.message}
                />
                <div className={styles.flexContainer}>
                    <Button
                        fullWidth={true}
                        variant={ButtonVariant.Primary}
                        classes={clx(styles.btn, styles.btnPrimary)}
                        type="submit"
                        disableButton={isPending}
                    >
                        Wyślij
                    </Button>
                    <Button
                        fullWidth={true}
                        onClick={handleClose}
                        classes={styles.btn}
                        variant={ButtonVariant.Light}
                        type="button"
                    >
                        Anuluj
                    </Button>
                </div>
            </form>
            {isPending && (
                <p className={styles.loading} aria-busy="true">
                    <Loader spinner/>
                </p>
            )}
        </Modal>
    );
};
