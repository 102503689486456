import axios from "axios";

export const deleteAccount = async (accountId: string): Promise<any> => {
  try {
    const response = await axios.delete(`/api/skillgu/delete/accounts/${accountId}`);
    return response.data;
  } catch (error) {
    console.error("Error while deleting account:", error);
    throw error;
  }
};