import React, {FC, useCallback, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

import stylesSessions from "../SessionForm/SessionForm.module.scss";
import {SubmitHandler, useForm} from "react-hook-form";
import {Button} from "@mui/material";
import ScheduleFormGeneralSettings from "./_components/ScheduleFormGeneralSettings/ScheduleFormGeneralSettings";
import ScheduleFormWeekDays from "./_components/ScheduleFormWeekDays/ScheduleFormWeekDays";
import {useSchedulesReducer} from "src/reducers/schedules";
import {ScheduleFormInputT} from "@customTypes/ScheduleFormInputT";
import {editMentorSchedule} from "@services/schedule/editScheduleService";
import {
    createScheduleMeeting,
    fetchAllSchedules,
} from "@services/schedule/scheduleService";
import {ScheduleType} from "@customTypes/schedule";
import {addMonths, setHours, setMinutes} from "date-fns";
import {weekdays, WeekdayT} from "./_types/WeekdayT";
import {WeekdayInputT} from "./_types/WeekdayInputT";

const revalidatingTimeout: Record<string, ReturnType<typeof setTimeout>> = {};

type Props = {
    data?: ScheduleFormInputT;
};

const today = new Date();

const defaultSlot = {
    dateFrom: setHours(setMinutes(today, 0), 9),
    dateTo: setHours(setMinutes(today, 0), 17),
};

const defaultValues: ScheduleFormInputT = {
    scheduleName: "",
    meetingLength: 30,
    type: "individual",
    cancelAvailable: false,
    dateFrom: today,
    dateTo: addMonths(today, 1),
    participantsNumber: 1,
    resign: true,
    weekdays: weekdays.reduce((acc, day) => {
        acc[day] = {
            isActivated: false,
            slots: [defaultSlot],
        };
        return acc;
    }, {} as Record<WeekdayT, WeekdayInputT>),
};

const ScheduleForm: FC<Props> = ({data}) => {
    const {scheduleId} = useParams();
    const navigate = useNavigate();
    const {
        formState,
        control,
        clearErrors,
        watch,
        getValues,
        handleSubmit,
        trigger,
        setFocus,
    } = useForm<ScheduleFormInputT>({
        values: data,
        defaultValues: defaultValues,
        mode: "all",
    });
    const sr = useSchedulesReducer();

    const meetingLengthValue = watch("meetingLength");

    const updateAndFetch = useCallback(() => {
        sr.reset();
        fetchAllSchedules().then((res) => {
            if (res.data) {
                const schedules: ScheduleType[] = res.data.map((elementFromAPI) => ({
                    id: elementFromAPI.id,
                    scheduleName: elementFromAPI.scheduleName,
                    meetTime: elementFromAPI.meetTime,
                    participant: elementFromAPI.participant,
                    assignedSession: elementFromAPI.assignedSession,
                    type: elementFromAPI.type,
                    scheduleEndDay: elementFromAPI.scheduleEndDay,
                    scheduleStartDay: elementFromAPI.scheduleStartDay,
                }));
                sr.updateRecords(schedules);
            }
        });
        navigate("/schedules");
    }, [sr, navigate]);

    const onSubmit: SubmitHandler<ScheduleFormInputT> = useCallback(
        (data) => {
            if (scheduleId) {
                editMentorSchedule(scheduleId, data)
                    .then(updateAndFetch)
                    .catch((error) => {
                        console.error("Error updating schedule:", error.message);
                    });
            } else {
                createScheduleMeeting(data)
                    .then(updateAndFetch)
                    .catch((error) => {
                        console.error("Error creating schedule meeting:", error.message);
                    });
            }
        },
        [navigate, scheduleId, sr, updateAndFetch]
    );

    const revalidate = useCallback(
        (path: string) => () => {
            const timeoutId = revalidatingTimeout[path];
            if (timeoutId) clearTimeout(timeoutId);

            revalidatingTimeout[path] = setTimeout(() => {
                trigger(path as keyof ScheduleFormInputT);
            }, 10);
        },
        [trigger]
    );

    useEffect(() => {
        // revalidate weekdays when meeting length changes
        revalidate("weekdays")();
    }, [meetingLengthValue, revalidate]);

    useEffect(() => {
        setFocus("scheduleName");
    }, [setFocus]);

    return (
        <form className={stylesSessions.form} onSubmit={handleSubmit(onSubmit)}>
            <ScheduleFormGeneralSettings
                formControl={control}
                formWatch={watch}
                formState={formState}
            />
            <ScheduleFormWeekDays
                formControl={control}
                formGetValues={getValues}
                formClearErrors={clearErrors}
                revalidate={revalidate}
                watch={watch}
            />
            <Button
                sx={{mt: 2}}
                fullWidth
                variant="contained"
                type="submit"
                disabled={!!Object.entries(formState.errors).length}
            >
                Zapisz zmiany
            </Button>
        </form>
    );
};

export default ScheduleForm;
