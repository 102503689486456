import React, {useEffect, useState} from "react";
import styles from "./SelectedDate.module.scss";
import clx from "classnames";
import {getFormattedTimeZone} from "@services/timezone/timeZoneHelper";

type Props = {
    className?: string;
    selectedDate: string;
    selectedTime: string;
};

export const SelectedDate = ({
                                 className,
                                 selectedDate,
                                 selectedTime,
                             }: Props) => {
    const [menteeTimeZone, setMenteeTimeZone] = useState<string>("");

    useEffect(() => {
        const browserTimeZone = getFormattedTimeZone()
        setMenteeTimeZone(browserTimeZone);
    }, []);


    return selectedTime || selectedDate ? (
        <div className={clx(styles.box, className)}>
            <h3>Wybrany termin</h3>
            <ul>
                {selectedDate ? <li>{selectedDate}</li> : null}
                {selectedTime ? <li>{selectedTime}</li> : null}
            </ul>
            {menteeTimeZone && (
                <div className={styles.timeZone}>
                    <span>Twoja strefa czasowa: <strong>{menteeTimeZone}</strong></span>
                </div>
            )}
        </div>
    ) : null;
};

