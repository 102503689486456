export function getFormattedTimeZone(): string {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const now = new Date();
    const offsetInMinutes = now.getTimezoneOffset(); // Offset in minutes from UTC
    const offsetHours = Math.abs(offsetInMinutes) / 60;
    const sign = offsetInMinutes <= 0 ? "+" : "-"; // Negative offset means positive UTC

    const offsetString = `${sign}${String(Math.floor(offsetHours)).padStart(2, "0")}:${String(
        offsetInMinutes % 60
    ).padStart(2, "0")}`;

    return `(UTC${offsetString}) ${timeZone}`;
}
