import { Resolver } from "react-hook-form";
import { ContactMentorFormTypes } from "./ContactMentorPopup";

export const resolver: Resolver<ContactMentorFormTypes> = async (values) => {
  type ErrorTypes = { [key: string]: { type: string; message: string } };
  const errors: ErrorTypes = {};

  if (!values.email) {
    errors.email = {
      type: "required",
      message: "Pole e-mail jest wymagane",
    };
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
    errors.email = {
      type: "pattern",
      message: "Nieprawidłowy format adresu e-mail",
    };
  }

  if (!values.message) {
    errors.message = {
      type: "required",
      message: "Treść wiadomości jest wymagana",
    };
  } else if (values.message.length < 20) {
    errors.message = {
      type: "minLength",
      message: "Treść wiadomości jest za krótka",
    };
  }

  return {
    values: Object.keys(errors).length === 0 ? values : {},
    errors,
  };
};
