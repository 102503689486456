export const translateDayNames = (label: string): string => {
    const translations: Record<string, string> = {
        Mon: "Pon",
        Tue: "Wt",
        Wed: "Śr",
        Thu: "Czw",
        Fri: "Pt",
        Sat: "Sob",
        Sun: "Niedz",
    };

    return label
        .split(" ")
        .map((word) => translations[word] || word)
        .join(" ");
};