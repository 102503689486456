import React, { useEffect, useState } from "react";
import {
    Autocomplete,
    TextField,
    Typography,
} from "@mui/material";
import { Controller, Path, FieldValues, Control, FormState } from "react-hook-form";
import { DropdownOption } from "@customTypes/dropdownOption";
import { StyledFeedbackWrapper, StyledInputWrapper } from "../_common/FormInput.styles";
import InputFeedback from "../InputFeedback/InputFeedback";

export type Feedback = {
    message: string;
    severity: "error" | "success";
};

interface Props<T extends FieldValues, OptionMetadataT> {
    label: string;
    name: Path<T>;
    control: Control<T>;
    formState: FormState<T>;
    getOptions: () => Promise<DropdownOption<OptionMetadataT>[]>;
    customFeedback?: Feedback[];
}

const FormInputSelectTimeZone = <T extends FieldValues, OptionMetadataT = undefined>({
                                                                                       control,
                                                                                       name,
                                                                                       customFeedback,
                                                                                       label,
                                                                                       formState,
                                                                                       getOptions,
                                                                                   }: Props<T, OptionMetadataT>) => {
    const [options, setOptions] = useState<DropdownOption<OptionMetadataT>[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let isMounted = true;
        getOptions()
            .then((data) => {
                if (isMounted) {
                    setOptions(data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error loading options:", error);
                setLoading(false);
            });

        return () => {
            isMounted = false;
        };
    }, [getOptions]);

    const feedback: Feedback[] = React.useMemo(() => {
        if (customFeedback) return customFeedback;
        const error = formState.errors[name];
        if (!error) return [];
        return [{ message: error.message as string, severity: "error" }];
    }, [formState, customFeedback, name]);

    return (
        <StyledInputWrapper>
            <Typography variant="buttonMd" color="secondary">
                {label}
            </Typography>
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                    <Autocomplete
                        options={options}
                        getOptionLabel={(option) => option.label}
                        loading={loading}
                        onChange={(event, newValue) => onChange(newValue?.value || "")}
                        value={options.find((option) => option.value === value) || null}
                        // isOptionEqualToValue={(option, value) => option.value === value}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputRef={ref}
                                label={loading ? "Loading..." : label}
                                variant="outlined"
                            />
                        )}
                    />
                )}
            />
            <StyledFeedbackWrapper>
                {feedback &&
                    feedback.map(({ message, severity }, index) => (
                        <InputFeedback
                            key={`${message}_${index}`}
                            message={message}
                            severity={severity}
                        />
                    ))}
            </StyledFeedbackWrapper>
        </StyledInputWrapper>
    );
};

export default FormInputSelectTimeZone;
