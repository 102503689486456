import React from "react";
import clx from "classnames";
import styles from "./MentorProduct.module.scss";
import {EmptyState} from "src/components/EmptyState"; // Import the reusable EmptyState component


type SocialMediaProvider =
    | "ebook"


type Props = {
    className?: string;
    isDesktop?: boolean;
} & Partial<Record<SocialMediaProvider, string>>;


export const MentorProduct = ({ isDesktop, className }: { isDesktop?: boolean; className?: string }) => {
    return (
        <div
            className={clx(
                styles.linksSection,
                {
                    [styles.border]: isDesktop,
                    [styles.desktop]: isDesktop,
                },
                className
            )}
        >
            {isDesktop ? <h5 className={styles.linksTitle}>Produkty</h5> : null}
            <EmptyState
                text="Aktualnie nie posiadam w swojej ofercie żadnych produktów"
                className={styles.emptyProductState} // Optional: Add additional styling if needed
            />
        </div>
    );
};
