import React, {FC} from "react";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";
import {translateDayNames} from "./calendarDayHelper";

type Props = {
    label: string;
};


const CalendarHeader: FC<Props> = ({ label }) => {
    const translatedLabel = translateDayNames(label);

    return (
        <Box sx={{ pb: 2 }}>
            <Typography color={"base.80"} variant="caption">
                {translatedLabel}
            </Typography>
        </Box>
    );
};

export default CalendarHeader

