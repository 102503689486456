import {format, setHours, setMinutes} from "date-fns";
import axios from "axios";
import {ScheduleFormInputT} from "@customTypes/ScheduleFormInputT";
import {weekdays, WeekdayT} from "../../pages/app/Schedules/screens/ScheduleForm/_types/WeekdayT";
import {WeekdayInputT} from "../../pages/app/Schedules/screens/ScheduleForm/_types/WeekdayInputT";
import {ScheduleDTO} from "@customTypes/scheduleDTO";

type WeekTimes = Record<WeekdayT, {
    from: { time: string },
    to: { time: string }
}[]>;

const extractTimeIntervalsFromDays = (weekdays: ScheduleFormInputT['weekdays']): WeekTimes => {
    return Object.entries(weekdays)
        .filter(([_, {isActivated}]) => isActivated)
        .reduce((acc, [day, {slots}]) => {
            acc[day as WeekdayT] = slots.map(({dateFrom, dateTo}) => ({
                from: {time: format(dateFrom, 'HH:mm')},
                to: {time: format(dateTo, 'HH:mm')}
            }));
            return acc;
        }, {} as WeekTimes);
};

export const getScheduleFormInitialData = async (scheduleId: string): Promise<ScheduleFormInputT> => {
    const response = await axios.get<{ data: ScheduleDTO }>(`/api/1.0/schedules/${scheduleId}`);
    const elements = response.data.data;

    const today = new Date();

    const weekTimes = Object.entries(elements.weekTimes || {}).reduce(
        (acc, [day, times]) => {
            const normalizedDay = day.toLowerCase();

            acc[normalizedDay] = times.map(({from, to}) => ({
                dateFrom: `2021-01-01T${from}:00`,
                dateTo: `2021-01-01T${to}:00`,
            }));

            return acc;
        },
        {} as Record<string, { dateFrom: string; dateTo: string }[]>
    );

    return {
        scheduleName: elements.scheduleName,
        meetingLength: elements.meetTime,
        type: 'individual',
        cancelAvailable: elements.resign,
        dateFrom: new Date(elements.scheduleStartDay),
        dateTo: new Date(elements.scheduleEndDay),
        participantsNumber: elements.participant,
        resign: elements.resign,
        weekdays: weekdays.reduce((element, day) => {
            element[day] = {
                isActivated: !!weekTimes[day],
                slots: weekTimes[day]?.map(({dateFrom, dateTo}) => ({
                    dateFrom: new Date(dateFrom),
                    dateTo: new Date(dateTo),
                })) || [{
                    dateFrom: setHours(setMinutes(today, 0), 9),
                    dateTo: setHours(setMinutes(today, 0), 15),
                }]
            };
            return element;
        }, {} as Record<WeekdayT, WeekdayInputT>)
    };
};

export const editMentorSchedule = async (scheduleId: string, updatedData: ScheduleFormInputT) => {
    try {

        const payload = {
            scheduleName: updatedData.scheduleName,
            dateFrom: updatedData.dateFrom.toISOString(),
            dateTo: updatedData.dateTo.toISOString(),
            meetTime: updatedData.meetingLength,
            resign: updatedData.resign,
            type: updatedData.type,
            participantsNumber: updatedData.participantsNumber,
            weekdays: extractTimeIntervalsFromDays(updatedData.weekdays)
        };

        const response = await axios.put(`/api/1.0/schedules/update/${scheduleId}`, payload);

        return response.data;
    } catch (error) {
        console.error("Failed to update schedule:", error);
        throw new Error("Failed to update schedule");
    }
};