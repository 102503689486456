import React, {FC} from "react";
import {Calendar as BigCalendar, dateFnsLocalizer, type CalendarProps} from 'react-big-calendar';
import {format, parse, startOfWeek, getDay} from 'date-fns';
import {pl} from 'date-fns/locale';
import {StyledCalendarWrapper} from "./Calendar.styles";
import CalendarToolbar from "./_components/CalendarToolbar/CalendarToolbar";
import CalendarHeader from "./_components/CalendarHeader/CalendarHeader";
import CalendarDateHeader from "./_components/CalendarDateHeader/CalendarDateHeader";
import CalendarEvent from "./_components/CalendarEvent/CalendarEvent";

const locales = {
    pl: pl,
};

// const localizer = dateFnsLocalizer({
//     format,
//     parse,
//     startOfWeek: () => startOfWeek(new Date(), {weekStartsOn: 1}),
//     getDay,
//     locales,
//     formats: {
//         dayFormat: (date: any) =>
//             date.toLocaleDateString('pl-PL', {weekday: 'short'}).replace(/^\w/, (c:any) => c.toUpperCase()),
//         weekdayFormat: (date: any) =>
//             date.toLocaleDateString('pl-PL', {weekday: 'short'}).replace(/^\w/, (c:any) => c.toUpperCase()),
//         dateFormat: 'dd.MM.yyyy',
//     }
// });

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }), // Ustaw pierwszy dzień tygodnia
    getDay,
    locales,
    formats: {
        weekdayFormat: (date: any) =>
            date.toLocaleDateString('pl-PL', { weekday: 'short' }).replace(/^\w/, (c:any) => c.toUpperCase()), // Ustaw skrócone nazwy dni tygodnia
        dayFormat: (date: any) =>
            date.toLocaleDateString('pl-PL', { weekday: 'short' }).replace(/^\w/, (c:any) => c.toUpperCase()),
        dateFormat: 'dd.MM.yyyy',
    },
});

type Props = {
    calendarProps: Pick<CalendarProps, 'events' | 'onNavigate'>
}

const CELL_SIZE = 160;

const Calendar: FC<Props> = ({calendarProps}) => {
    return (
        <StyledCalendarWrapper cellSize={CELL_SIZE}>
            <BigCalendar
                localizer={localizer}
                defaultView='month'
                views={['month']}
                components={{
                    toolbar: CalendarToolbar,
                    header: CalendarHeader,
                    month: {dateHeader: CalendarDateHeader},
                    eventWrapper: CalendarEvent,
                }}
                messages={{
                    allDay: 'Cały dzień',
                    previous: 'Poprzedni',
                    next: 'Następny',
                    today: 'Dziś',
                    month: 'Miesiąc',
                    week: 'Tydzień',
                    day: 'Dzień',
                    agenda: 'Agenda',
                    date: 'Data',
                    time: 'Czas',
                    event: 'Wydarzenie',

                    showMore: (count) => `+${count} innych`,
                }}
                {...calendarProps}
            />
        </StyledCalendarWrapper>
    );
};

export default Calendar;
